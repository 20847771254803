import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { KeycloakCredentialsService } from '../../services/keycloak-credentials.service';

@Directive({
  selector: '[disallowTo]',
  standalone: true,
})
export class DisallowToDirective {
  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private credentials: KeycloakCredentialsService
  ) {}

  @Input()
  set disallowTo(roles: string[]) {
    if (this.credentials.roles?.some((role) => roles.includes(role))) {
      this.viewContainer.clear();
      return;
    }

    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
