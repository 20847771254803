<pds-form [formGroup]="form">
  <pds-form-row grid="1-1">
    <pds-form-field label="Nome">
      <input type="text" formControlName="firstName" />
    </pds-form-field>
    <pds-form-field label="Sobrenome">
      <input type="text" formControlName="lastName" />
    </pds-form-field>
  </pds-form-row>

  <pds-form-row grid="1-1">
    <pds-form-field label="E-mail">
      <input type="text" formControlName="email" pdsMask="email" [alias]="true" />
    </pds-form-field>
    <pds-form-field label="CPF">
      <input type="text" formControlName="document" pdsMask="999.999.999-99" />
    </pds-form-field>
  </pds-form-row>

  <pds-form-row>
    <pds-form-field label="Grupo">
      <pds-multiple-select [source]="groups$" formControlName="groups" />
    </pds-form-field>
  </pds-form-row>

  <pds-form-actions>
    <pds-button [disabled]="form.invalid" (click)="onClickSubmit()">Salvar</pds-button>
  </pds-form-actions>
</pds-form>
