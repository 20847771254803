import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import Inputmask from 'inputmask';

@Directive({
  selector: '[pdsMask]',
})
export class MaskDirective implements AfterViewInit {
  @Input('pdsMask')
  mask: string;

  @Input()
  alias: boolean;

  constructor(private elementRef: ElementRef) {
    this.mask = '';
    this.alias = false;
  }

  ngAfterViewInit() {
    if (this.alias) Inputmask({ alias: this.mask }).mask(this.elementRef.nativeElement);
    else Inputmask({ mask: this.mask }).mask(this.elementRef.nativeElement);
  }
}
