<ul class="pds-breadcrumb">
  <li class="pds-breadcrumb__item" *ngFor="let item of items; let i = index">
    <ng-container *ngIf="i + 1 < items.length; else lastBreadcrumbItem">
      <a class="link" [routerLink]="item.path">
        <span>{{ item.label }}</span>
      </a>
      <span class="pds-breadcrumb__item__dash">/</span>
    </ng-container>
    <ng-template #lastBreadcrumbItem>
      <span>{{ item.label }}</span>
    </ng-template>
  </li>
</ul>
