import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { KeycloakCredentialsService } from '../../services/keycloak-credentials.service';

@Directive({
  selector: '[allowTo]',
  standalone: true,
})
export class AllowToDirective {
  hasCreated = false;

  constructor(private templateRef: TemplateRef<unknown>, private viewContainer: ViewContainerRef, private credentials: KeycloakCredentialsService) {}

  @Input()
  set allowTo(roles: Array<Array<string>>) {
    const hasRole = this.hasRole(roles);

    if (!hasRole) {
      this.viewContainer.clear();
      return;
    }

    if (!this.hasCreated) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasCreated = true;
    }
  }

  private hasRole(roles: Array<Array<string>> = []) {
    if (!roles.length) {
      return true;
    }

    return roles.some((group) => group.every((role) => this.credentials.roles?.includes(role)));
  }
}
