import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { DesignSystemModule } from '@peca/design-system';
import { AllowToDirective, KeycloakCredentialsService } from '@peca/keycloak';

@Component({
  selector: 'peca-main-navigation-menu',
  templateUrl: './main-navigation-menu.component.html',
  styleUrls: ['./main-navigation-menu.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, AllowToDirective, DesignSystemModule],
})
export class MainNavigationMenuComponent {
  @Input()
  opened: boolean;
  @Input()
  menu: any[];
  expanded: boolean;
  keycloakService: KeycloakCredentialsService;

  constructor() {
    this.menu = [];
    this.keycloakService = inject(KeycloakCredentialsService);
    this.opened = false;
    this.expanded = true;
  }

  onClickToogleMenu() {
    this.expanded = !this.expanded;
  }
}
