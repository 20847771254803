import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  BehaviorSubject,
  Subject,
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  map,
  takeUntil,
} from 'rxjs';

import { AutocompleteItem, AutocompleteItemSource } from './autocomplete.model';

@Component({
  selector: 'pds-autocomplete[source]',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent implements AfterViewInit, OnDestroy {
  @Input('source')
  source$!: BehaviorSubject<AutocompleteItemSource>;

  @Input('history')
  history$?: BehaviorSubject<AutocompleteItemSource>;

  @Input()
  placeholder = '';

  @Input()
  delay = 1000;

  @Input()
  noSearchMessage = 'Utilize o campo de busca para encontrar resultados.';

  @ViewChild('searchInput')
  searchElementRef!: ElementRef;

  @Output()
  autocomplete: EventEmitter<string> = new EventEmitter();

  destroy$ = new Subject<void>();

  showCombobox = false;

  search = '';

  _value: AutocompleteItem | null = null;

  onChange!: (value: AutocompleteItem | null) => void;

  onTouched!: () => void;

  disabled = false;

  get value() {
    return this._value;
  }

  set value(value: AutocompleteItem | null) {
    if (value === this._value) return;

    this._value = value;
    this.onChange(this._value);
  }

  writeValue(value: AutocompleteItem | null) {
    this._value = value;
    this.selectItem(value);
  }

  registerOnChange(fn: (value: AutocompleteItem | null) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get searchChanges$() {
    const search = this.searchElementRef.nativeElement;

    return fromEvent<KeyboardEvent>(search, 'keyup').pipe(
      map((event) => (event.target as HTMLFormElement)['value'].trim()),
      distinctUntilChanged(),
      debounceTime(this.delay),
      takeUntil(this.destroy$)
    );
  }

  ngAfterViewInit() {
    this.searchChanges$.subscribe(this.onSearchChanges.bind(this));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onSearchChanges(search: string) {
    this.onChange(null);
    this.autocomplete.next(search);
  }

  selectItem(item: AutocompleteItem | null) {
    this.value = item;
    this.search = item?.label || '';
    this.showCombobox = false;
  }

  handleSearchFocusEvent() {
    this.showCombobox = true;
    this.search = '';
    this.value = null;
    this.onTouched();
    this.autocomplete.next('');
  }

  handleOverlayClick() {
    this.showCombobox = false;

    if (!this.value) {
      this.search = '';
      this.autocomplete.next('');
    }
  }

  handleSearchKeydownEvent(event: KeyboardEvent) {
    if (event.code === 'Tab') event.preventDefault();
  }
}
