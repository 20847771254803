import { CommonModule } from '@angular/common';
import {} from '@angular/common/http';
import { DesignSystemModule } from '@peca/design-system';
import { AfterViewInit, Component, forwardRef, inject } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { Validators } from '../../validators';
import { AddressService } from './address.service';
import { ToastService } from '../toast/toast.service';
import { Address, UFOption, ViaCEPAddress } from './address.model';

@Component({
  selector: 'peca-address',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DesignSystemModule],
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [
    AddressService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressComponent),
      multi: true,
    },
  ],
})
export class AddressComponent implements ControlValueAccessor, AfterViewInit {
  disabled: boolean;
  changed!: (value: Address | null) => void;
  touched!: () => void;
  form: FormGroup;
  addressService: AddressService;
  ufOptions: UFOption[];
  toast: ToastService;

  constructor() {
    this.disabled = false;
    this.form = this.getForm();
    this.addressService = inject(AddressService);
    this.ufOptions = [
      { name: 'Acre', uf: 'AC' },
      { name: 'Alagoas', uf: 'AL' },
      { name: 'Amapá', uf: 'AP' },
      { name: 'Amazonas', uf: 'AM' },
      { name: 'Bahia', uf: 'BA' },
      { name: 'Ceará', uf: 'CE' },
      { name: 'Distrito Federal', uf: 'DF' },
      { name: 'Espírito Santo', uf: 'ES' },
      { name: 'Goiás', uf: 'GO' },
      { name: 'Maranhão', uf: 'MA' },
      { name: 'Mato Grosso', uf: 'MT' },
      { name: 'Mato Grosso do Sul', uf: 'MS' },
      { name: 'Minas Gerais', uf: 'MG' },
      { name: 'Pará', uf: 'PA' },
      { name: 'Paraíba', uf: 'PB' },
      { name: 'Paraná', uf: 'PR' },
      { name: 'Pernambuco', uf: 'PE' },
      { name: 'Piauí', uf: 'PI' },
      { name: 'Rio de Janeiro', uf: 'RJ' },
      { name: 'Rio Grande do Norte', uf: 'RN' },
      { name: 'Rio Grande do Sul', uf: 'RS' },
      { name: 'Rondônia', uf: 'RO' },
      { name: 'Roraima', uf: 'RR' },
      { name: 'Santa Catarina', uf: 'SC' },
      { name: 'São Paulo', uf: 'SP' },
      { name: 'Sergipe', uf: 'SE' },
      { name: 'Tocantins', uf: 'TO' },
    ];
    this.toast = inject(ToastService);
  }

  get controls() {
    return {
      id: this.form.get('id') as FormControl,
      street: this.form.get('street') as FormControl,
      neighborhood: this.form.get('neighborhood') as FormControl,
      state: this.form.get('state') as FormControl,
      city: this.form.get('city') as FormControl,
      zipCode: this.form.get('zipCode') as FormControl,
      number: this.form.get('number') as FormControl,
      complement: this.form.get('complement') as FormControl,
      description: this.form.get('description') as FormControl,
    };
  }

  ngAfterViewInit() {
    this.form.valueChanges.subscribe((val) => {
      if (this.form.invalid) {
        this.changed(null);
        return;
      }

      this.changed(val);
    });
  }

  onClickSearchCEP() {
    if (this.controls.zipCode.invalid) return;

    const zipCode = this.controls.zipCode.value;
    const errorMessage = `Não foi possível encontrar o CEP "${zipCode}"`;

    this.addressService.getCEPData(zipCode).subscribe({
      next: (data: ViaCEPAddress) => {
        if (data.erro) return this.toast.failure(errorMessage);
        this.setValueFromService(data);
      },
      error: (err) => {
        this.toast.failure(errorMessage);
        this.clearAndEnable();
      },
    });
  }

  private getForm() {
    const form = new FormGroup({
      id: new FormControl(''),
      zipCode: new FormControl('', [Validators.required, Validators.cep]),
      street: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required]),
      neighborhood: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      complement: new FormControl('', []),
      description: new FormControl('', []),
    });

    return form;
  }

  private setValueFromService(value: ViaCEPAddress) {
    this.controls.street.setValue(value.logradouro);
    this.controls.neighborhood.setValue(value.bairro);
    this.controls.state.setValue(value.uf);
    this.controls.city.setValue(value.localidade);
    this.controls.number.setValue('');
    this.controls.complement.setValue('');
    this.controls.description.setValue('');
  }

  private clearAndEnable() {
    this.controls.street.setValue('');
    this.controls.neighborhood.setValue('');
    this.controls.state.setValue('');
    this.controls.city.setValue('');
    this.form.enable();
  }

  writeValue(value: Address) {
    this.form.setValue({
      id: value?.id || '',
      street: value?.street || '',
      neighborhood: value?.neighborhood || '',
      state: value?.state || '',
      city: value?.city || '',
      zipCode: value?.zipCode || '',
      number: value?.number || '',
      complement: value?.complement || '',
      description: value?.description || '',
    });
  }

  registerOnChange(fn: (value: Address | null) => void) {
    this.changed = fn;
  }

  registerOnTouched(fn: () => void) {
    this.touched = fn;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) this.form.disable();
    else this.form.enable();
  }
}
