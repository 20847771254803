import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpecificAggregationResponseDto } from '../dtos/responses/SpecificAggregationResponseDto';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { SpecificationDto } from '../dtos/others/SpecificationDto';
import { SpecificationResponseDto } from '../dtos/responses/SpecificationResponseDto';

@Injectable()
export class AggregationsService {
  constructor(private httpClient: HttpClient) {}

  public searchProductGroup(query: string): Observable<SpecificAggregationResponseDto[]> {
    return this.httpClient.get<SpecificAggregationResponseDto[]>(
      `${environment.gateway.url}/catalog/v1/products/aggregations/resumed/product-group?query=${query}`
    );
  }

  public searchSpecifications(productGroupId: string, selectedSpecifications: SpecificationDto[]): Observable<SpecificationResponseDto[]> {
    return new Observable((subscriber) => {
      this.httpClient
        .post<SpecificAggregationResponseDto[]>(`${environment.gateway.url}/catalog/v1/products/aggregations/resumed/specifications`, {
          productGroupId: productGroupId,
          specifications: selectedSpecifications,
        })
        .subscribe((response) => {
          const specifications: SpecificationResponseDto[] = [];

          for (const item of response) {
            const specification = specifications.find((x) => x.specificationId === item.id);
            if (specification) {
              specification.values.push(item.value);
              continue;
            }

            specifications.push({
              specificationId: item.id,
              name: item.description,
              values: [item.value],
            });
          }
          subscriber.next(specifications.sort((a, b) => a.name.localeCompare(b.name)));
          subscriber.complete();
        });
    });
  }
}
