<div role="alert" class="peca-toast --{{ toast?.type }}" [class.--active]="active">
  <h3 class="peca-toast__title">
    <span>{{ toast?.type === 'success' ? 'Sucesso' : 'Erro' }}</span>
    <button type="button" class="peca-toast__close" (click)="onClickClose()">
      <span class="icon">close</span>
    </button>
  </h3>
  <div class="peca-toast__message">
    {{ toast?.message }}
  </div>
</div>
