<div [class.--is-disabled]="disabled" class="checkbox-container" (click)="toggle()">
  <input type="checkbox" [checked]="value" [disabled]="disabled" />

  <span class="icon" [class.--is-disabled]="disabled">
    <ng-container *ngIf="value; else unchecked">check_box</ng-container>
    <ng-template #unchecked>check_box_outline_blank</ng-template>
  </span>

  <ng-container *ngIf="label">
    <div class="checkbox-container__label">
      <label>{{ label }}</label>

      <ng-container *ngIf="sublabel">
        <small>{{ sublabel }}</small>
      </ng-container>
    </div>
  </ng-container>
</div>
