import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { QueriesComponent } from '../queries/queries.component';
import {} from '@angular/common/http';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { SpecificationInput } from '../createquery/createquery.component';
import { SpecificationDto } from '../../dtos/others/SpecificationDto';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    QueriesComponent,
    MatPaginatorModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatSelectModule,
  ],
  selector: 'peca-specification',
  templateUrl: './specification.component.html',
  styleUrls: ['./specification.component.scss'],
})
export class SpecificationComponent implements OnInit {
  @Output() public selected = new EventEmitter<SpecificationDto>();
  @Output() public deleted = new EventEmitter<void>();
  @Input() disabled = false;

  public selectedValues: string[] = [];

  ngOnInit(): void {
    this.loadAvailableValues();

    if (this.disabled) {
      this.selectedValues = this.specificationInput?.selectedSpecification.values ?? [];
    }
  }

  loadAvailableValues(): void {
    if (this.disabled) return;
    if (!this.specificationInput) return;

    this.availableValues = this.specificationInput.selectedSpecification.values;
  }

  idChanged(event: MatSelectChange) {
    if (this.disabled) return;
    if (!this.specificationInput) return;

    const selected = this.specificationInput.availableSpecifications.find((x) => x.id === event.value);
    if (!selected) return;
    this.specificationInput.selectedSpecification = selected;

    this.loadAvailableValues();

    this.emitSelected();
  }

  valueChanged(event: MatSelectChange) {
    if (this.disabled) return;
    this.selectedValues = event.value;

    this.emitSelected();
  }

  emitSelected(): void {
    if (this.disabled) return;
    if (!this.specificationInput) return;

    this.selected.emit({
      id: this.specificationInput.selectedSpecification.id,
      description: this.specificationInput.selectedSpecification.description,
      values: this.selectedValues,
    });
  }

  removeSpec() {
    if (this.disabled) return;
    this.deleted.emit();
  }

  @Input() public specificationInput?: SpecificationInput;
  public availableValues: string[] = [];
}
