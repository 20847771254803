import { AbstractControl } from '@angular/forms';

function normalize(date: string) {
  const parsedDate = date.substring(0, 10);

  if (parsedDate.indexOf('/') < 0) {
    return parsedDate;
  }

  const [day, month, year] = parsedDate.split('/');
  return `${year}-${month}-${day}`;
}

export const date = (control: AbstractControl) => {
  const { value } = control;

  if (!value) return null;

  const date = new Date(normalize(value));

  if (!isNaN(date.getTime())) return null;

  return { date: { message: 'Data inválida' } };
};
