import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Subject, delay, filter, takeUntil } from 'rxjs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

import { KeycloakCredentialsService } from '../services/keycloak-credentials.service';

@Component({
  standalone: true,
  templateUrl: './keycloak-auth.component.html',
  styleUrls: ['./keycloak-auth.component.scss'],
  imports: [RouterModule],
  providers: [KeycloakService],
})
export class KeycloakAuthComponent implements OnInit, OnDestroy {
  private readonly router: Router;
  private readonly route: ActivatedRoute;
  private readonly keycloakCredentialsService: KeycloakCredentialsService;
  private readonly destroyRef$: Subject<void>;

  constructor() {
    this.route = inject(ActivatedRoute);
    this.router = inject(Router);
    this.keycloakCredentialsService = inject(KeycloakCredentialsService);
    this.destroyRef$ = new Subject();
    this.keycloakCredentialsService.events$
      .pipe(
        filter((event) => !!event),
        delay(1000),
        takeUntil(this.destroyRef$)
      )
      .subscribe(this.onEventChanges.bind(this));
  }

  async ngOnInit() {
    await this.keycloakCredentialsService.init();
  }

  ngOnDestroy() {
    this.destroyRef$.next();
  }

  onEventChanges() {
    const redirectUrl = this.route.snapshot.queryParams['redirectUrl'] || '/';
    this.router.navigateByUrl(redirectUrl);
  }
}
