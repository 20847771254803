import { Directive, ElementRef, inject, Input, OnInit, Renderer2 } from '@angular/core';

type PDSButtonType = 'default' | 'primary' | 'secondary' | 'terciary' | 'icon' | '';

@Directive({
  selector: 'button[pdsButton],a[pdsButton]',
})
export class PDSButtonDirective implements OnInit {
  @Input()
  modifiers?: string;

  @Input()
  pdsButton: PDSButtonType = 'default';

  elementRef = inject(ElementRef);

  renderer = inject(Renderer2);

  get hostElement() {
    return this.elementRef.nativeElement as HTMLElement;
  }

  ngOnInit() {
    this.renderer.addClass(this.hostElement, 'pds-button');
    this.renderer.addClass(this.hostElement, `pds-button-${this.pdsButton || 'default'}`);
    this.modifiers?.split(' ').forEach((modifier) => this.renderer.addClass(this.hostElement, `--is-${modifier}`));
  }
}
