import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { of, switchMap, throwError } from 'rxjs';

export class BaseHttpClient {
  protected readonly http: HttpClient;

  constructor() {
    this.http = inject(HttpClient);
  }

  protected download(resource: string) {
    const responseType = 'arraybuffer';
    const observe = 'response';
    return this.http.get(resource, { responseType, observe }).pipe(switchMap(this.mapFileDownload));
  }

  protected mapFileDownload(res: HttpResponse<ArrayBuffer>) {
    const { status, body } = res;
    const contentType = res.headers.get('Content-Type');

    if (status !== 200 || !body || !contentType) {
      return throwError(() => new Error(res.statusText));
    }

    return of(URL.createObjectURL(new Blob([body], { type: contentType })));
  }

  protected prepareQuery(query: object) {
    let params = new HttpParams();

    Object.entries(query as object)
      .filter(([, value]) => this.isNotEmpty(value))
      .forEach(([key, value]) => {
        params = params.append(key, value);
      });

    return params;
  }

  private isNotEmpty(value: unknown) {
    if (value === null || typeof value === 'undefined') return false;

    if (typeof value === 'string' && String(value).trim().length === 0) return false;

    if (Array.isArray(value) && !value.length) return false;

    return true;
  }
}
