<pds-module-header title="Queries" ctaLabel="Voltar" ctaPath="/" modifier="outlined">
  <pds-new-breadcrumb [items]="breadcrumb" />
  <div class="container">
    <div class="section query">
      <h2>Query</h2>
      <mat-form-field>
        <mat-label>Query</mat-label>
        <input matInput type="text" [(ngModel)]="query" required [disabled]="isReadOnly" />
        <mat-hint color="primary">{{ errors.get('query') }}</mat-hint>
      </mat-form-field>
    </div>

    <div class="section">
      <h2>Grupo de Produto</h2>
      <div class="search">
        <mat-form-field *ngIf="!isReadOnly">
          <mat-label>Pesquisar</mat-label>
          <input matInput type="text" [(ngModel)]="searchText" (keyup.enter)="searchProductGroup()" [disabled]="loadingSearch" />
        </mat-form-field>

        <button class="search-btn" pdsButton color="primary" (click)="searchProductGroup()" *ngIf="!isReadOnly">
          <div class="btn-content" *ngIf="!loadingSearch">
            <mat-icon>search</mat-icon>
            Pesquisar
          </div>
          <div class="btn-spinner" *ngIf="loadingSearch">
            <mat-spinner diameter="30" strokeWidth="2" color="accent"></mat-spinner>
          </div>
        </button>

        <mat-form-field>
          <mat-select [(ngModel)]="selectedProductGroup" [disabled]="isReadOnly">
            <mat-option *ngFor="let aggregation of productGroups" [value]="aggregation.id">{{ aggregation.value }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="section specs">
      <h2>Especificações</h2>

      <div *ngFor="let specInput of specifications" class="spec">
        <peca-specification [specificationInput]="specInput" [disabled]="isReadOnly" (selected)="changedSelectedSpec(specInput, $event)" (deleted)="removeSpec(specInput)"></peca-specification>
      </div>

      <button pdsButton color="primary" class="add" (click)="addSpec()" *ngIf="!isReadOnly">
        <div class="btn-content" *ngIf="!loadingSpec">
          <mat-icon>add</mat-icon>
          Adicionar Especificação
        </div>
        <div class="btn-spinner" *ngIf="loadingSpec">
          <mat-spinner diameter="30" strokeWidth="2" color="accent"></mat-spinner>
        </div>
      </button>
    </div>

    <div class="section section-salles-channels">
      <h2>Canais de vendas</h2>

      <div class="section-salles-channels__row">
        <mat-form-field>
          <mat-label>Canais habilitados</mat-label>
          <mat-select [formControl]="whiteListControl" multiple>
            @for (channel of salesChannels$ | async; track channel) {
            <mat-option [value]="channel.id">{{ channel.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Canais desabilitados</mat-label>
          <mat-select [formControl]="blackListControl" multiple>
            @for (channel of salesChannels$ | async; track channel) {
            <mat-option [value]="channel.id">{{ channel.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="section">
      <ng-container *ngIf="selectedProductGroup && query && !isReadOnly">
        <button pdsButton [disabled]="saving" (click)="save()">salvar</button>
      </ng-container>
    </div>
  </div>
</pds-module-header>
