import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { Dayweek, daysOfWeek } from '../../pipes/dayweek/dayweek.model';
import { BusinessHoursValidator } from './business-hours.validator';
import { BusinessHours } from './business-hours.model';

export class BusinessHoursForm {
  instance: FormGroup;

  constructor() {
    this.instance = this.buildFormGroup();
  }

  get week() {
    return this.instance.get('week') as FormArray;
  }

  private buildFormGroup() {
    const week = daysOfWeek.map((day) => this.buildDayofWeekGroup(day));
    return new FormGroup({ week: new FormArray(week) });
  }

  private buildDayofWeekGroup(day: Dayweek) {
    const group = new FormGroup({
      dayOfWeek: new FormControl(day),
      openHour: new FormControl('', [BusinessHoursValidator.hour]),
      closeHour: new FormControl('', [BusinessHoursValidator.hour]),
      startBreakHour: new FormControl('', [BusinessHoursValidator.hour]),
      endBreakHour: new FormControl('', [BusinessHoursValidator.hour]),
    });

    group.addValidators([BusinessHoursValidator.range(group)]);

    return group;
  }

  parseToBusinessHours() {
    const businessHours: BusinessHours[] = [];

    for (const day of this.instance.value.week) {
      if (!day.openHour) continue;

      const { dayOfWeek, openHour, closeHour, startBreakHour, endBreakHour } =
        day;

      if (!startBreakHour) {
        businessHours.push({ dayOfWeek, openHour, closeHour });
        continue;
      }

      businessHours.push(
        { dayOfWeek, openHour, closeHour: startBreakHour },
        { dayOfWeek, openHour: endBreakHour, closeHour }
      );
    }

    return businessHours;
  }

  static buildBusinessHours() {
    const controls = daysOfWeek.map((day) => {
      return new FormControl({
        dayOfWeek: new FormControl(day),
        openHour: new FormControl(''),
        closeHour: new FormControl(''),
      });
    });

    return new FormArray(controls);
  }

  setValue(businessHours: BusinessHours[]) {
    if (!businessHours) return;
    this.week.controls.forEach((day) => {
      const slot = businessHours
        .filter((slot) => slot.dayOfWeek === day.value.dayOfWeek)
        .sort((a, b) => a.openHour.localeCompare(b.openHour));

      if (!slot.length) return;

      day.setValue({
        dayOfWeek: day.value.dayOfWeek,
        openHour: this.defineHours(slot, true, false),
        closeHour: this.defineHours(slot, false, false),
        startBreakHour: this.defineHours(slot, true, true),
        endBreakHour: this.defineHours(slot, false, true),
      });
    });
  }

  private defineHours(slot: BusinessHours[], open: boolean, pause: boolean) {
    const [openning, clossing] = slot;

    let hour = '';

    if (!openning || (pause && !clossing)) hour = '';
    else if (!clossing) hour = open ? openning.openHour : openning.closeHour;
    else if (pause) hour = open ? openning.closeHour : clossing.openHour;
    else hour = open ? openning.openHour : clossing.closeHour;

    return hour;
  }
}
