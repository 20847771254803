import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[pdsTooltip]',
})
export class PDSTooltipDirective {
  @Input('pdsTooltip')
  tooltipText: string;
  tooltipElement: HTMLElement | null;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.tooltipText = '';
    this.tooltipElement = null;
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltipElement) {
      this.showTooltip();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltipElement) {
      this.hideTooltip();
    }
  }

  private showTooltip() {
    const element = this.renderer.createElement('span');
    element.innerText = this.tooltipText;
    this.renderer.appendChild(document.body, element);

    // Estilizando o tooltip
    this.renderer.setStyle(element, 'position', 'absolute');
    this.renderer.setStyle(element, 'background-color', '#333');
    this.renderer.setStyle(element, 'color', '#fff');
    this.renderer.setStyle(element, 'padding', '5px 10px');
    this.renderer.setStyle(element, 'border-radius', '4px');
    this.renderer.setStyle(element, 'font-size', '12px');
    this.renderer.setStyle(element, 'white-space', 'nowrap');
    this.renderer.setStyle(element, 'pointer-events', 'none');

    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = {
      top: hostPos.top + window.scrollY - element.offsetHeight - 10,
      left: hostPos.left + window.scrollX + hostPos.width / 2 - element.offsetWidth / 2,
    };
    this.renderer.setStyle(element, 'top', `${tooltipPos.top}px`);
    this.renderer.setStyle(element, 'left', `${tooltipPos.left}px`);

    this.tooltipElement = element;
  }

  private hideTooltip() {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
  }
}
