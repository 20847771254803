<div class="order-steps">
  <div
    class="order-steps__step"
    *ngFor="let step of _steps(); let i = index"
    [ngClass]="{
      '--completed': step.status === 'completed',
      '--exception': step.status === 'exception'
    }"
  >
    <span class="icon material-symbols-outlined">
      {{ step.status === 'exception' ? 'cancel' : step.icon }}
    </span>

    <p class="label">{{ step.status === 'exception' ? 'CANCELADO' : step.label }}</p>
    <p class="datetime" *ngIf="step.date">{{ step.date }}</p>
  </div>
</div>
