import { Route } from '@angular/router';
import { KeycloakAuthComponent } from '@peca/keycloak';
import { keycloakGuard } from '@peca/keycloak';

import { PDSMainNavigationComponent } from '@peca/design-system';
import { queryRoutes } from './modules/query/query.routes';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    component: KeycloakAuthComponent,
  },
  {
    path: '',
    component: PDSMainNavigationComponent,
    canActivate: [keycloakGuard([])],
    children: [
      {
        path: 'query',
        children: queryRoutes,
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'query',
  },
];
