import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateQueryComponent } from '../createquery/createquery.component';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CreateQueryComponent, CommonModule],
  selector: 'peca-querydetails',
  templateUrl: './querydetails.component.html',
  styleUrls: ['./querydetails.component.scss'],
})
export class QueryDetailsComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}
  public queryId?: string | null;

  ngOnInit(): void {
    this.queryId = this.route.snapshot.paramMap.get('id');
    if (!this.queryId) this.router.navigate(['/']);
  }
}
