import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingOverlayService {
  loading$: BehaviorSubject<boolean>;

  constructor() {
    this.loading$ = new BehaviorSubject<boolean>(false);
  }

  get listen() {
    return this.loading$.asObservable();
  }

  set(loading: boolean) {
    this.loading$.next(loading);
  }
}
