import { Component, OnInit, inject } from '@angular/core';
import { LoadingOverlayService } from './loading-overlay.service';

@Component({
  selector: 'peca-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
})
export class LoadingOverlayComponent implements OnInit {
  service: LoadingOverlayService;

  constructor() {
    this.service = inject(LoadingOverlayService);
  }

  get loading$() {
    return this.service.listen;
  }

  ngOnInit() {
    this.service.listen.subscribe((loading) => {
      if (loading) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflowY = 'scroll';
      }
    });
  }
}
