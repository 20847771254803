import { AbstractControl } from '@angular/forms';

export const contactType = (type: 'EMAIL' | 'CELLPHONE' | 'PHONE' | 'WHATSAPP') => {
  return (control: AbstractControl) => {
    const email = new RegExp(/^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/);
    const phone = new RegExp(/^\(\d{2}\) \d{4,5}-\d{4,5}$/);

    if (!control.value) return null;

    switch (type) {
      case 'EMAIL':
        if (email.test(control.value)) return null;
        break;
      case 'WHATSAPP':
      case 'CELLPHONE':
      case 'PHONE':
        if (phone.test(control.value)) return null;
        break;
    }

    return { contactType: { message: 'Contato inválido' } };
  };
};
