<mat-card-content *ngIf="query">
  <span>{{ query.query }}</span>
  <div class="actions">
    <button pdsButton="secondary" modifiers="icon text" [routerLink]="['query', query.id]">
      <span class="icon">visibility</span>
    </button>

    <button pdsButton="secondary" modifiers="icon text" (click)="delete()">
      <span class="icon">delete</span>
    </button>
  </div>
</mat-card-content>
