import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpecificAggregationResponseDto } from '../dtos/responses/SpecificAggregationResponseDto';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { SpecificationDto } from '../dtos/others/SpecificationDto';

@Injectable()
export class AggregationsService {
  constructor(private httpClient: HttpClient) {}

  public searchProductGroup(
    query: string
  ): Observable<SpecificAggregationResponseDto[]> {
    return this.httpClient.get<SpecificAggregationResponseDto[]>(
      `${environment.gateway.url}/catalog/v1/products/aggregations/resumed/product-group?query=${query}`
    );
  }

  public searchSpecifications(
    productGroupId: string,
    selectedSpecifications: SpecificationDto[]
  ): Observable<SpecificationDto[]> {
    return new Observable((subscriber) => {
      this.httpClient
        .post<SpecificAggregationResponseDto[]>(
          `${environment.gateway.url}/catalog/v1/products/aggregations/resumed/specifications`,
          {
            productGroupId: productGroupId,
            specifications: selectedSpecifications,
          }
        )
        .subscribe((response) => {
          const specifications: SpecificationDto[] = [];

          for (const item of response) {
            const specification = specifications.find((x) => x.id === item.id);
            if (specification) {
              specification.values.push(item.value);
              continue;
            }

            specifications.push({
              id: item.id,
              description: item.description,
              values: [item.value],
            });
          }

          subscriber.next(
            specifications.sort((a, b) =>
              a.description.localeCompare(b.description)
            )
          );
          subscriber.complete();
        });
    });
  }
}
