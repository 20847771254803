<pds-module-header title="Queries" ctaLabel="Nova Query" ctaPath="/create-query">
  <div class="search">
    <mat-form-field>
      <mat-label>Pesquisar</mat-label>
      <input matInput type="text" [(ngModel)]="searchText" (keyup.enter)="search()" />
    </mat-form-field>

    <button pdsButton (click)="search()">Pesquisar</button>
  </div>

  <mat-card class="sort">
    <mat-card-content>
      <button class="sort-btn" (click)="changeSortDirection()">
        <span>Query</span>
        <span class="icon">{{ arrowIcon }}</span>
      </button>
    </mat-card-content>
  </mat-card>

  <peca-queries [queries]="queriesResponse?.items ?? []" [loading]="loading" (reload)="loadQueries()"></peca-queries>

  <mat-paginator
    [length]="queriesResponse?.totalItems ?? 0"
    [pageSize]="sortablePageRequest.size"
    [pageSizeOptions]="[5, 10, 25, 100]"
    [pageIndex]="sortablePageRequest.page"
    (page)="onPageChange($event)"
    aria-label="Selecionar página"
    itemsPerPageLabel="Itens por página"
    nextPageLabel="Próxima página"
    previousPageLabel="Página anterior"
    firstPageLabel="Primeira página"
    lastPageLabel="Última página"
  ></mat-paginator>
</pds-module-header>
