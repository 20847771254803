import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: 'td[pdsColActions]',
})
export class TableColActionsDirective implements OnInit {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    const content = Array.from(this.elementRef.nativeElement.children);
    const div = this.renderer.createElement('div');

    this.renderer.setStyle(div, 'display', 'flex');
    this.renderer.setStyle(div, 'align-items', 'center');
    this.renderer.setStyle(div, 'justify-content', 'center');
    this.renderer.setStyle(div, 'gap', '8px');

    for (const item of content) {
      this.renderer.removeChild(this.elementRef.nativeElement, item);
      this.renderer.appendChild(div, item);
    }

    this.renderer.appendChild(this.elementRef.nativeElement, div);
  }
}
