import { AbstractControl } from '@angular/forms';

function validate(value: string) {
  const cpf = value.replace(/[.|-]/g, '');
  let sum = 0;
  let rest;

  if (cpf == '00000000000') return false;

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if (rest == 10 || rest == 11) rest = 0;
  if (rest != parseInt(cpf.substring(9, 10))) return false;

  sum = 0;

  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if (rest == 10 || rest == 11) rest = 0;
  if (rest != parseInt(cpf.substring(10, 11))) return false;

  return true;
}

export const cpf = (control: AbstractControl) => {
  const { value } = control;
  const matcher = new RegExp(/^\d{3}.\d{3}.\d{3}-\d{2}$/);

  if (!value || (matcher.test(value) && validate(value))) return null;

  return { cpf: { message: 'CPF inválido' } };
};
