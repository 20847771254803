import { Pipe, PipeTransform } from '@angular/core';
import { Dayweek } from './dayweek.model';

@Pipe({
  name: 'dayweek',
  standalone: true,
})
export class DayweekPipe implements PipeTransform {
  transform(value: Dayweek | null) {
    if (!value) return '';

    const labels = {
      SUNDAY: 'Domingo',
      MONDAY: 'Segunda-feira',
      TUESDAY: 'Terça-feira',
      WEDNESDAY: 'Quarta-feira',
      THURSDAY: 'Quinta-feira',
      FRIDAY: 'Sexta-feira',
      SATURDAY: 'Sábado',
    };

    return labels[value];
  }
}
