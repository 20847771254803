import { inject } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SpecificationResponseDto } from 'apps/dialect/src/dtos/responses/SpecificationResponseDto';

export class QueryForm {
  private formBuilder: FormBuilder;
  readonly group: FormGroup;

  constructor() {
    this.formBuilder = inject(FormBuilder);

    this.group = new FormGroup(
      {
        query: new FormControl(''),
        searchText: new FormControl(''),
        productGroups: new FormControl(''),
        whiteListControl: new FormControl([]),
        blackListControl: new FormControl([]),
        quantity: new FormControl(1, [Validators.required, Validators.min(1)]),
        specifications: this.formBuilder.array([]),
      },
      { validators: this.saleChanelValidator }
    );
  }

  saleChanelValidator(control: AbstractControl) {
    const group = control as FormGroup;

    const whiteListControl = group.get('whiteListControl');
    const blackListControl = group.get('blackListControl');

    if (!whiteListControl || !blackListControl) {
      return null;
    }

    const whiteList = whiteListControl.value || [];
    const blackList = blackListControl.value || [];

    const hasConflict = whiteList.some((item: any) => blackList.includes(item));

    if (!hasConflict) {
      return null;
    }

    return {
      settings: {
        message: 'Existe um conflito entre a lista de vendedores atendidos e excluídos',
      },
    };
  }

  get disabled() {
    return this.group.disabled;
  }

  disableForm() {
    this.group.disable();
  }

  get enabled() {
    return this.group.enabled;
  }

  enableForm() {
    this.group.enable();
  }

  get controls() {
    return {
      query: this.group.get('query') as FormControl,
      searchText: this.group.get('searchText') as FormControl,
      productGroups: this.group.get('productGroups') as FormControl,
      whiteListControl: this.group.get('whiteListControl') as FormControl,
      blackListControl: this.group.get('blackListControl') as FormControl,
      specifications: this.group.get('specifications') as FormArray,
      quantity: this.group.get('quantity') as FormControl,
    };
  }

  get values() {
    const formValue = this.group.value;

    const specifications = (this.group.get('specifications') as FormArray).controls.map((control) => {
      const groupValue = control.value;
      return {
        specificationId: groupValue?.specificationId,
        name: groupValue?.name,
        values: groupValue?.values,
      };
    });
    return {
      query: formValue?.query,
      searchText: formValue?.searchText,
      productGroups: formValue?.productGroups,
      whiteListControl: formValue?.whiteListControl,
      blackListControl: formValue?.blackListControl,
      specifications: specifications,
      quantity: formValue?.quantity,
    };
  }

  setValue(
    query: string,
    searchText: string,
    productGroups: string,
    whiteList: string[],
    blackList: string[],
    specifications: SpecificationResponseDto[],
    quantity: number
  ) {
    this.controls.query.setValue(query);
    this.controls.searchText.setValue(searchText);
    this.controls.productGroups.setValue(productGroups);
    this.controls.whiteListControl.setValue(whiteList);
    this.controls.blackListControl.setValue(blackList);
    this.controls.quantity.setValue(quantity);
    this.specifications = specifications as SpecificationResponseDto[];
  }

  set specifications(specifications: SpecificationResponseDto[]) {
    const specificationsControl = specifications.map((wh) =>
      this.formBuilder.group({
        specificationId: [wh.specificationId],
        name: [wh.name, Validators.required],
        values: [wh.values, Validators.required],
      })
    );

    this.group.setControl('specifications', this.formBuilder.array(specificationsControl));
  }

  pushNewSpecification(specification: SpecificationResponseDto) {
    const newSpecification = this.formBuilder.group({
      specificationId: [specification.specificationId, Validators.required],
      name: [specification.name],
      values: [{ value: specification.values, disabled: true }, Validators.required],
    });

    this.controls.specifications.push(newSpecification);
  }
}
