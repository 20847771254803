import { Route } from '@angular/router';
import { QueryFormComponent } from './components/query-form/query-form.component';
import { QueryListComponent } from './components/query-list/query-list.component';

export const queryRoutes: Route[] = [
  {
    path: 'create-query',
    component: QueryFormComponent,
  },
  {
    path: ':queryId',
    component: QueryFormComponent,
  },
  {
    path: '',
    component: QueryListComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];
