import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PersistQueryService {
  route: ActivatedRoute;
  router: Router;

  constructor() {
    this.route = inject(ActivatedRoute);
    this.router = inject(Router);
  }

  rewriteQueryString(storage: string, query: unknown) {
    this.persist(storage, query);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: {
        q: btoa(JSON.stringify(query)),
      },
    });
  }

  persist(storage: string, query: unknown) {
    sessionStorage.setItem(storage, btoa(JSON.stringify(query)));
  }

  read<T>(storage: string) {
    const value = sessionStorage.getItem(storage);
    const query = this.route.snapshot.queryParams['q'];

    if (value) return JSON.parse(atob(value)) as T;

    if (query) return JSON.parse(atob(query)) as T;

    return null;
  }
}
