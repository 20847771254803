import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { Pagination, PaginationInitialState } from './pagination.model';

@Component({
  selector: 'pds-pagination[pagination]',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnDestroy {
  @Input('pagination')
  pagination$!: Subject<Pagination>;

  @Output()
  readonly paginate: EventEmitter<Pagination>;

  readonly acceptedSizes: number[];

  readonly destroy$: Subject<void>;

  pagination: Pagination;

  pageControl: number;

  sizeControl: number;

  constructor() {
    this.pagination = PaginationInitialState;
    this.paginate = new EventEmitter<Pagination>();
    this.acceptedSizes = [12, 24, 36];
    this.destroy$ = new Subject<void>();
    this.pageControl = PaginationInitialState.page;
    this.sizeControl = PaginationInitialState.size;
  }

  get disabledPreviousPage() {
    return this.pagination.disabled || this.pagination.page === 1;
  }

  get disabledNextPage() {
    return this.pagination.disabled || this.pagination.page >= this.pagination.total;
  }

  ngOnInit() {
    this.pagination$.pipe(takeUntil(this.destroy$)).subscribe((pagination) => {
      this.pagination = pagination;
      this.pageControl = pagination.page;
      this.sizeControl = pagination.size;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  handleFirstPageEventClick() {
    const page = 1;
    this.paginate.next({ ...this.pagination, page });
  }

  handlePreviousPageEventClick() {
    const page = this.pageControl - 1;
    this.paginate.next({ ...this.pagination, page });
  }

  handleNextPageEventClick() {
    const page = this.pageControl + 1;
    this.paginate.next({ ...this.pagination, page });
  }

  handleLastPageEventClick() {
    const page = this.pagination.total;
    this.paginate.next({ ...this.pagination, page });
  }

  handleSizeValueChanges(event: Event) {
    const { value } = event.target as HTMLFormElement;
    const size = parseInt(value);

    if (!this.acceptedSizes.includes(size)) return;

    this.paginate.next({ ...PaginationInitialState, size });
  }

  handlePageValueChanges(event: Event) {
    const { value } = event.target as HTMLFormElement;
    const page = parseInt(value);

    if (isNaN(page) || page < 0 || page > this.pagination.total) return;

    this.paginate.next({ ...this.pagination, page });
  }
}
