<div class="main-navigation">
  <header class="main-navigation-header">
    <div>
      <button class="main-navigation-header__trigger" (click)="onClickToogleMenu()">
        <span class="icon">menu</span>
      </button>

      <a routerLink="/" title="Peça Aí - Backoffice">
        <pds-logotype></pds-logotype>
      </a>
    </div>

    <div class="main-navigation-header__account">
      <div class="main-navigation-header__account__user">
        <span class="icon">account_circle</span>
        <span>Olá, {{ username }}</span>
      </div>

      <div class="main-navigation-header__account__logout">
        <button type="button" title="Sair" (click)="onClickLogout()">
          <span class="icon">logout</span>
        </button>
      </div>
    </div>
  </header>

  <div class="main-navigation__content">
    @if (config.groups.length) {
    <pds-main-navigation-menu [opened]="opened" [groups]="config.groups" />
    }

    <main class="main-navigation__content__outlet">
      <router-outlet />
    </main>

    @if (config.enableInsights) {
    <pds-main-navigation-insights />
    }
  </div>
</div>
