import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, inject, Input, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DesignSystemModule } from '@peca/design-system';

interface DocumentPreview {
  type: string;
  url: string;
  id: string;
  title: string;
}

@Component({
  standalone: true,
  selector: 'peca-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
  imports: [CommonModule, DesignSystemModule],
})
export class DocumentPreviewComponent {
  @Input()
  disableDeleteAction: boolean;
  @Input()
  disableDownloadAction: boolean;
  @Output()
  delete: EventEmitter<DocumentPreview>;
  options: DocumentPreview | null;
  url: SafeUrl | null;
  sanitizer: DomSanitizer;
  active: boolean;

  constructor() {
    this.disableDeleteAction = false;
    this.disableDownloadAction = false;
    this.delete = new EventEmitter<DocumentPreview>();
    this.options = null;
    this.url = null;
    this.sanitizer = inject(DomSanitizer);
    this.active = false;
  }

  open(options: DocumentPreview) {
    this.options = options;
    this.active = true;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(options.url);
  }

  close() {
    this.options = null;
    this.active = false;
    this.url = null;
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleCloseClickEvent() {
    this.close();
  }

  onClickDelete() {
    if (!this.options) return;
    this.delete.next(this.options);
  }

  onClickDownload() {
    if (!this.options) return;
    const a = document.createElement('a');
    a.setAttribute('href', this.options.url);
    a.setAttribute('download', '');
    a.setAttribute('target', '_blank');
    a.click();
    a.remove();
  }
}
