import { Component, Input } from '@angular/core';
import { BreadcrumbItem } from './breadcrumb.model';

@Component({
  selector: 'pds-new-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class NewBreadcrumbComponent {
  @Input()
  items: BreadcrumbItem[];

  constructor() {
    this.items = [];
  }
}
