import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Insight {
  type: 'text' | 'currency';
  label: string;
  value: string;
}

@Injectable({ providedIn: 'root' })
export class InsightsService {
  private insights$: BehaviorSubject<Insight[]>;

  constructor() {
    this.insights$ = new BehaviorSubject<Insight[]>([]);
  }

  get insights() {
    return this.insights$.asObservable();
  }

  push(insights: Insight[]) {
    this.insights$.next(insights);
  }

  clear() {
    this.insights$.next([]);
  }
}
