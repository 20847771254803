<aside class="main-navigation-insights" [class.--is-expanded]="expanded">
  <h2 class="main-navigation-insights__title">
    <div class="main-navigation-insights__title__icon">
      <span class="icon">swap_driving_apps_wheel</span>
    </div>
    <div class="main-navigation-insights__title__label">Indicadores</div>
  </h2>
  <div *ngIf="insights$ | async as insights" class="main-navigation-insights__cards">
    <div class="main-navigation-insights__items" [hidden]="!expanded">
      <ng-container *ngIf="!insights.length">
        <h4 class="main-navigation-insights__no-insights">Nenhum indicador encontrado</h4>
      </ng-container>

      <ng-container *ngFor="let insight of insights">
        <div class="main-navigation-insights__item">
          <h4 class="main-navigation-insights__item__title">{{ insight.label }}</h4>
          <ng-container *ngIf="insight.type === 'text'">
            <div class="main-navigation-insights__item__value">{{ insight.value }}</div>
          </ng-container>
          <ng-container *ngIf="insight.type === 'currency'">
            <div class="main-navigation-insights__item__value">{{ insight.value | currency : 'BRL' }}</div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <button type="menu" class="main-navigation-insights__trigger" [class.--is-expanded]="expanded" (click)="onClickToogleSidebar()">
    <span class="icon">keyboard_double_arrow_left</span>
  </button>
</aside>
