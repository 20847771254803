import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { QueryResponseDto } from '../../dtos/responses/QueryResponseDto';
import { DialectService } from '../../services/dialect.service';
import { QueriesComponent } from '../queries/queries.component';
import { SortablePageRequestDto } from '../../dtos/requests/SortablePageRequestDto';
import { PageDto } from '../../dtos/responses/PageDto';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { KeycloakCredentialsService } from '@peca/keycloak';
import { DesignSystemModule } from '@peca/design-system';
import { HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    QueriesComponent,
    MatPaginatorModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    DesignSystemModule,
  ],
  providers: [DialectService],
  selector: 'peca-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public queriesResponse?: PageDto<QueryResponseDto>;
  public sortablePageRequest: SortablePageRequestDto = {
    page: 0,
    size: 25,
    sortBy: 'query',
    sortDirection: 'asc',
    search: '',
  };
  public searchText = '';
  public loading = true;

  constructor(private dialectService: DialectService, private keycloak: KeycloakCredentialsService) {}

  ngOnInit(): void {
    this.loadQueries();
  }

  get credentials$() {
    return this.keycloak.events$;
  }

  loadQueries(): void {
    this.loading = true;
    this.dialectService.getQueries(this.sortablePageRequest).subscribe((query: PageDto<QueryResponseDto>) => {
      this.queriesResponse = query;
      this.loading = false;
    });
  }

  onPageChange(event: PageEvent): void {
    this.sortablePageRequest.page = event.pageIndex;
    this.sortablePageRequest.size = event.pageSize;
    this.loadQueries();
  }

  search() {
    this.sortablePageRequest.search = this.searchText;
    this.sortablePageRequest.page = 0;
    this.loadQueries();
  }

  clear() {
    this.searchText = '';
    this.search();
  }

  get arrowIcon(): string {
    return this.sortablePageRequest.sortDirection === 'asc' ? 'arrow_downward_alt' : 'arrow_upward_alt';
  }

  changeSortDirection(): void {
    if (this.sortablePageRequest.sortDirection === 'asc') {
      this.sortablePageRequest.sortDirection = 'desc';
    } else {
      this.sortablePageRequest.sortDirection = 'asc';
    }

    this.loadQueries();
  }
}
