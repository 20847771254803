import { APP_INITIALIZER } from '@angular/core';
import { KeycloakService, KeycloakOptions } from 'keycloak-angular';

export const keyclockProviderFactory = (config: KeycloakOptions) => {
  return {
    provide: APP_INITIALIZER,
    useFactory: (keycloak: KeycloakService) => () => keycloak.init(config),
    multi: true,
    deps: [KeycloakService],
  };
};
