import { HttpParams } from '@angular/common/http';

function isNotEmpty(value: unknown) {
  if (value === null || typeof value === 'undefined') return false;

  if (typeof value === 'string' && String(value).length === 0) return false;

  return true;
}

export const prepareQuery = (query: object) => {
  let params = new HttpParams();

  Object.entries(query as object)
    .filter(([, value]) => isNotEmpty(value))
    .forEach(([key, value]) => {
      params = params.append(key, value);
    });

  return params;
};
