import { FormGroup } from '@angular/forms';

export const contact = (group: FormGroup) => {
  return () => {
    const { type, contact } = group.value;
    const email = new RegExp(
      /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/
    );
    const phone = new RegExp(
      /^\([1-9]{2}\) (?:[2-8]|9[0-9])[0-9]{3}-[0-9]{4}$/
    );

    switch (type) {
      case 'EMAIL':
        if (email.test(contact)) return null;
        break;
      case 'CELLPHONE':
      case 'PHONE':
        if (phone.test(contact)) return null;
        break;
    }

    return { contact: { message: 'Contato inválido' } };
  };
};
