import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DesignSystemModule } from '@peca/design-system';
import { Subject, distinctUntilChanged, filter, takeUntil } from 'rxjs';

import { BusinessHoursForm } from './business-hours.form';
import { DayweekPipe } from '../../pipes/dayweek/dayweek.pipe';
import { BusinessHours } from './business-hours.model';

@Component({
  selector: 'peca-business-hours[value][disabled]',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DayweekPipe, DesignSystemModule],
  templateUrl: './business-hours.component.html',
  styleUrls: ['./business-hours.component.scss'],
})
export class BusinessHoursComponent implements OnInit, OnDestroy, OnChanges {
  @Output()
  readonly changes: EventEmitter<BusinessHours[]>;

  @Input()
  value!: Array<BusinessHours>;

  @Input()
  disabled!: boolean;

  readonly destroyRef$: Subject<void>;

  readonly form: BusinessHoursForm;

  constructor() {
    this.changes = new EventEmitter<BusinessHours[]>();
    this.destroyRef$ = new Subject();
    this.form = new BusinessHoursForm();
  }

  ngOnInit() {
    this.form.setValue(this.value);
    this.form.instance.valueChanges
      .pipe(
        takeUntil(this.destroyRef$),
        distinctUntilChanged(),
        filter(() => this.form.instance.valid)
      )
      .subscribe(this.onValueChanges.bind(this));
  }

  ngOnChanges(changes: SimpleChanges) {
    const { disabled } = changes;
    this.onDisableStateChange(disabled);
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  onDisableStateChange(disabled: SimpleChange) {
    if (!disabled) return;

    if (disabled.currentValue) {
      this.form.instance.disable();
    } else {
      this.form.instance.enable();
    }
  }

  onValueChanges() {
    this.changes.next(this.form.parseToBusinessHours());
  }
}
