<div class="document-preview-wrapper" *ngIf="active && options">
  <div class="document-preview" role="dialog">
    <div class="document-preview__header">
      <h2 class="document-preview__header__title">{{ options.title }}</h2>
      <div class="document-preview__header__close">
        <button (click)="handleCloseClickEvent()">
          <span class="material-symbols-outlined">close</span>
        </button>
      </div>
    </div>

    <div class="document-preview__content">
      <embed [src]="url" [type]="options.type" width="100%" />
    </div>

    <div class="document-preview__actions">
      @if (!disableDownloadAction) {
      <button pdsButton type="button" (click)="onClickDownload()">Download <span class="icon">download</span></button>
      } @if (!disableDeleteAction) {
      <button pdsButton type="button" (click)="onClickDelete()">Excluir <span class="icon">delete_forever</span></button>
      }
    </div>
  </div>
</div>
