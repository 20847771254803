import { Route } from '@angular/router';
import { CreateQueryComponent } from './components/query-form/createquery.component';
import { HomeComponent } from './components/query-list/home.component';

export const queryRoutes: Route[] = [
  {
    path: 'remove-me-later',
    component: CreateQueryComponent,
  },
  {
    path: 'create-query',
    component: CreateQueryComponent,
  },
  {
    path: ':queryId',
    component: CreateQueryComponent,
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];
