import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[pdsFormImmutable][formControlName]',
})
export class FormImmutableDirective implements OnInit {
  @Input('pdsFormImmutable')
  shouldDisable: boolean;

  constructor(private el: ElementRef, private renderer: Renderer2, private control: NgControl) {
    this.shouldDisable = false;
  }

  ngOnInit() {
    if (!this.control || !this.shouldDisable) return;

    const parent = this.el.nativeElement.parentNode;
    const immutable = this.renderer.createElement('input');

    this.renderer.setAttribute(immutable, 'type', 'text');
    this.renderer.setProperty(immutable, 'disabled', true);
    this.renderer.setProperty(immutable, 'value', this.control.value);
    this.renderer.appendChild(parent, immutable);
    this.renderer.removeChild(parent, this.el.nativeElement);
  }
}
