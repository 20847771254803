<div class="pds-select-wrapper">
  <div class="pds-select" [class.--is-disabled]="disabled">
    <div class="pds-select__selected-list">
      @for (selected of selecteds$ | async; track $index) {
      <span class="pds-select__selected-list__item" (click)="onClickRemove(selected)">
        <span>{{ selected.label }}</span>
        <span class="icon">close</span>
      </span>
      } @empty {
      <div class="pds-select__placehoder" (click)="onTouch()">Selecione</div>
      }
    </div>
    <div role="button" class="pds-select__trigger" (click)="onTouch()">
      @if (opened$ | async) {
      <span class="icon">close</span>
      } @else {
      <span class="icon">arrow_drop_down</span>
      }
    </div>
  </div>

  <div class="pds-select-dropdown" [class.--is-opened]="opened$ | async">
    <div class="pds-select-dropdown__search" [hidden]="disableSearch">
      <input #searchInput type="text" class="pds-select-dropdown__search__input" [placeholder]="searchPlaceholder" />
    </div>

    <ul role="listbox" class="pds-select-dropdown__list">
      @for (option of options$ | async; track $index) {
      <li role="listitem" class="pds-select-dropdown__list__item" (click)="onSelect(option)">
        <span class="icon" [class.--is-selected]="option.selected">{{ option.selected ? 'check_box' : 'check_box_outline_blank' }}</span>
        <span>{{ option.label }}</span>
      </li>
      } @empty {
      <span class="pds-select-dropdown__list__no-results">nenhum resultado encontrado</span>
      }
    </ul>
  </div>
</div>
