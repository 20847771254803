import { Component, Input } from '@angular/core';

@Component({
  selector: 'pds-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input()
  type: 'button' | 'submit' = 'button';

  @Input()
  modifier: 'primary' | 'outlined' | 'danger' = 'primary';

  @Input()
  disabled = false;

  @Input()
  icon: string | undefined;
}
