import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DesignSystemModule } from '@peca/design-system';
import { PDSMainNavigationComponent } from './main-navigation/main-navigation.component';

@Component({
  standalone: true,
  imports: [RouterModule, PDSMainNavigationComponent, DesignSystemModule],
  selector: 'peca-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {}
