import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { SallesChannelResponse } from '../dtos/responses/salles-channel.model';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SellerService {
  private http: HttpClient;

  constructor() {
    this.http = inject(HttpClient);
  }

  suggest(search: string) {
    const params = { search };
    const resource = `${environment.gateway.url}/backoffice/v1/sales-channel/suggestions`;
    return this.http.get<SallesChannelResponse[]>(resource, { params }).pipe(map((res) => res.map((i) => ({ ...i, id: i.id.toString() }))));
  }
}
