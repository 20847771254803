import { Route } from '@angular/router';
import { KeycloakAuthComponent } from '@peca/keycloak';
import { keycloakGuard } from '@peca/keycloak';

import { HomeComponent } from './home/home.component';
import { CreateQueryComponent } from './createquery/createquery.component';
import { QueryDetailsComponent } from './editquery/querydetails.component';

export const appRoutes: Route[] = [
  {
    path: 'query/:id',
    pathMatch: 'full',
    component: QueryDetailsComponent,
    canActivate: [keycloakGuard([])],
  },
  {
    path: 'remove-me-later',
    pathMatch: 'full',
    component: QueryDetailsComponent,
    canActivate: [keycloakGuard([])],
  },
  {
    path: 'create-query',
    pathMatch: 'full',
    component: CreateQueryComponent,
    canActivate: [keycloakGuard([])],
  },
  {
    path: 'auth',
    component: KeycloakAuthComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
    canActivate: [keycloakGuard([])],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];
