<ng-container *ngIf="form">
  <div class="business-hours">
    <table [formGroup]="form.instance">
      <thead>
        <tr>
          <th></th>
          <th>Abertura</th>
          <th>Inicio do almoço</th>
          <th>Final do almoço</th>
          <th>Fechamento</th>
        </tr>
      </thead>
      <tbody formArrayName="week">
        <ng-container *ngFor="let day of form.week.controls; let i = index">
          <tr [formGroupName]="i" [class.ng-invalid]="day.invalid">
            <th>{{ day.get('dayOfWeek')?.value | dayweek }}</th>
            <td><input type="text" formControlName="openHour" placeholder="--:--" pdsMask="99:00" /></td>
            <td><input type="text" formControlName="startBreakHour" placeholder="--:--" pdsMask="99:00" /></td>
            <td><input type="text" formControlName="endBreakHour" placeholder="--:--" pdsMask="99:00" /></td>
            <td><input type="text" formControlName="closeHour" placeholder="--:--" pdsMask="99:00" /></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</ng-container>
