import { Injectable, inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { KeycloakCredentialsType } from '../models/keycloak-credentials.model';

@Injectable({
  providedIn: 'root',
})
export class KeycloakCredentialsService {
  private route: ActivatedRoute;
  private keycloakService: KeycloakService;
  private credentials$: BehaviorSubject<KeycloakCredentialsType>;

  constructor() {
    this.keycloakService = inject(KeycloakService);
    this.credentials$ = new BehaviorSubject<KeycloakCredentialsType>(null);
    this.route = inject(ActivatedRoute);
  }

  get events$() {
    return this.credentials$.asObservable();
  }

  get roles() {
    return this.credentials$.value ? this.credentials$.value.roles : null;
  }

  get token() {
    return this.credentials$.value ? this.credentials$.value.token : null;
  }

  set credentials(credentials: KeycloakCredentialsType) {
    this.credentials$.next(credentials);
  }

  get credentials() {
    return this.credentials$.value;
  }

  async init() {
    const isLoggedIn = await this.keycloakService.isLoggedIn();
    const redirectUrl = this.route.snapshot.queryParams['redirectUrl'] || '/';

    if (!isLoggedIn) {
      await this.keycloakService.login({
        redirectUri: window.location.origin + redirectUrl,
      });

      return;
    }

    if (this.keycloakService.isTokenExpired()) {
      await this.keycloakService.updateToken();
    }

    try {
      const roles = this.keycloakService.getUserRoles();
      const token = await this.keycloakService.getToken();
      const [, payload] = token.split('.');
      const { name, email } = JSON.parse(atob(payload));

      this.credentials = { roles, token, username: name, email };
    } catch (e) {
      // TODO: add observability (Sentry like)
      await this.keycloakService.login({
        redirectUri: window.location.origin + redirectUrl,
      });
    }
  }

  async logout() {
    await this.keycloakService.logout();
  }
}
