import { Injectable } from '@angular/core';
import { Toast } from './toast.model';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToastService {
  private toast$: Subject<Toast>;

  constructor() {
    this.toast$ = new Subject<Toast>();
  }

  get listen() {
    return this.toast$.asObservable();
  }

  success(message: string) {
    this.toast$.next({ type: 'success', message });
  }

  failure(message: string) {
    this.toast$.next({ type: 'failure', message });
  }

  warning(message: string) {
    this.toast$.next({ type: 'warning', message });
  }
}
