import { HttpClient } from '@angular/common/http';
import { environment as env } from '../environments/environment';
import { QueryResponseDto } from '../dtos/responses/QueryResponseDto';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SortablePageRequestDto } from '../dtos/requests/SortablePageRequestDto';
import { PageDto } from '../dtos/responses/PageDto';
import { CreateQueryRequestDto } from '../dtos/requests/CreateQueryRequestDto';

interface Pageable {
  page?: number;
  size?: number;
  search?: string;
}

function toPageable(sortablePageRequestDto: SortablePageRequestDto): Pageable {
  return {
    page: sortablePageRequestDto.page,
    size: sortablePageRequestDto.size,
    search: sortablePageRequestDto.search || '',
  };
}

@Injectable()
export class DialectService {
  constructor(private httpClient: HttpClient) {}

  private toQueryParams(object: object): string {
    const params = Object.keys(object)
      .map((key) => `${key}=${object[key as keyof object]}`)
      .join('&');
    return params.replace(/[^=&]+=&/g, '').replace(/&*[^=&]+=$/, '');
  }

  getQuery(id: string): Observable<QueryResponseDto> {
    return this.httpClient.get<QueryResponseDto>(
      env.local ? `http://localhost:8080/api/v1/queries/${id}` : `${env.gateway.url}/catalog-dialect/v1/queries/${id}`
    );
  }

  getQueries(sortablePageRequestDto: SortablePageRequestDto): Observable<PageDto<QueryResponseDto>> {
    const params = this.toQueryParams(toPageable(sortablePageRequestDto));
    return this.httpClient.get<PageDto<QueryResponseDto>>(
      env.local ? `http://localhost:8080/api/v1/queries?${params}` : `${env.gateway.url}/catalog-dialect/v1/queries?${params}`
    );
  }

  deleteQuery(id: string): Observable<void> {
    return this.httpClient.delete<void>(env.local ? `http://localhost:8080/api/v1/queries/${id}` : `${env.gateway.url}/catalog-dialect/v1/queries/${id}`);
  }

  saveQuery(queryRequest: CreateQueryRequestDto): Observable<void> {
    return this.httpClient.post<void>(env.local ? `http://localhost:8080/api/v1/queries` : `${env.gateway.url}/catalog-dialect/v1/queries`, queryRequest);
  }

  updateQuery(queryId: string, queryRequest: CreateQueryRequestDto): Observable<void> {
    return this.httpClient.put<void>(
      env.local ? `http://localhost:8080/api/v1/queries` : `${env.gateway.url}/catalog-dialect/v1/queries/${queryId}`,
      queryRequest
    );
  }
}
