import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { DesignSystemModule } from '@peca/design-system';
import { KeycloakCredentialsService } from '@peca/keycloak';
import { Subject, filter } from 'rxjs';
import { MainNavigationMenuComponent } from './main-navigation-menu/main-navigation-menu.component';
import { MainNavigationInsightsComponent } from './main-navigation-insights/main-navigation-insights.component';

@Component({
  standalone: true,
  selector: 'peca-main-navigation',
  imports: [CommonModule, RouterOutlet, RouterLink, RouterLinkActive, DesignSystemModule, MainNavigationMenuComponent, MainNavigationInsightsComponent],
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
})
export class PDSMainNavigationComponent implements OnInit, OnDestroy {
  @Input()
  enableInsights: boolean;
  @Input()
  menu: any[];
  router: Router;
  opened: boolean;
  destroyRef$: Subject<void>;

  constructor(private keycloakService: KeycloakCredentialsService) {
    this.router = inject(Router);
    this.enableInsights = false;
    this.menu = [
      {
        name: 'Cadastros',
        modules: [
          {
            path: '',
            label: 'Queries',
            icon: 'convert_to_text',
            roles: [],
          },
        ],
      },
    ];
    this.opened = false;
    this.destroyRef$ = new Subject<void>();
  }

  get username() {
    return this.keycloakService.credentials?.username;
  }

  ngOnDestroy() {
    this.destroyRef$.next();
    this.destroyRef$.unsubscribe();
  }

  ngOnInit() {
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => (this.opened = false));
  }

  onClickToogleMenu() {
    this.opened = !this.opened;
  }
}
