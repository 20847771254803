import { Component, Input } from '@angular/core';

@Component({
  selector: 'pds-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss'],
})
export class FormSectionComponent {
  @Input()
  title: string | undefined;
}
