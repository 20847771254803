import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'pds-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input()
  label!: string | undefined;

  @Input()
  sublabel?: string | undefined;

  private _value = false;

  private onChange!: (value: boolean) => void;

  private onTouched!: () => void;

  disabled = false;

  get value(): boolean {
    return this._value;
  }

  set value(val: boolean) {
    if (val !== this._value) {
      this._value = val;
      this.onChange(this._value);
    }
  }

  writeValue(value: boolean): void {
    this._value = value;
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  toggle(): void {
    if (this.disabled) return;
    this.value = !this.value;
    this.onTouched();
  }
}
