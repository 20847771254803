<pds-form-section [formGroup]="form">
  <pds-form-row grid="1-1-1">
    <pds-form-field label="CEP" action="search" (clicked)="onClickSearchCEP()">
      <input type="text" formControlName="zipCode" placeholder="CEP" pdsMask="99999-999" (keyup.enter)="onClickSearchCEP()" />
    </pds-form-field>
    <pds-form-field label="Endereço">
      <input type="text" formControlName="street" placeholder="Endereço" />
    </pds-form-field>
    <pds-form-field label="Número">
      <input type="text" formControlName="number" placeholder="Número" />
    </pds-form-field>
  </pds-form-row>

  <pds-form-row grid="1-1-1">
    <pds-form-field label="Bairro">
      <input type="text" formControlName="neighborhood" placeholder="Bairro" />
    </pds-form-field>
    <pds-form-field label="Cidade">
      <input type="text" formControlName="city" placeholder="Cidade" />
    </pds-form-field>
    <pds-form-field label="Estado">
      <select form="state" formControlName="state">
        <option value="" disabled selected>Selecione</option>
        <option *ngFor="let ufOption of ufOptions" [value]="ufOption.uf">
          {{ ufOption.name }}
        </option>
      </select>
    </pds-form-field>
  </pds-form-row>

  <pds-form-row grid="1-2">
    <pds-form-field label="Complemento">
      <input type="text" formControlName="complement" placeholder="Complemento" />
    </pds-form-field>
    <pds-form-field label="Descrição">
      <input type="text" formControlName="description" placeholder="Descrição" />
    </pds-form-field>
  </pds-form-row>
</pds-form-section>
