import { Dayweek } from '../../pipes/dayweek/dayweek.model';

export interface BusinessHours {
  dayOfWeek: Dayweek;
  openHour: string;
  closeHour: string;
}

export interface CommonBusinessHours {
  dayOfWeek: Dayweek;
  openHour: string;
  closeHour: string;
  startBreakHour: string;
  endBreakHour: string;
}

export interface CommonBusinessHoursForm {
  week: CommonBusinessHours[];
}
