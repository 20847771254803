import { HTTP_INTERCEPTORS, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Provider, inject } from '@angular/core';
import { finalize } from 'rxjs';
import { LoadingOverlayService } from './loading-overlay.service';

@Injectable({ providedIn: 'root' })
export class LoadingOverlayInterceptor implements HttpInterceptor {
  private readonly loading: LoadingOverlayService;

  constructor() {
    this.loading = inject(LoadingOverlayService);
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    this.loading.set(true);
    return next.handle(req.clone()).pipe(finalize(() => this.loading.set(false)));
  }
}

export const loadingOverlayInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  multi: true,
  useClass: LoadingOverlayInterceptor,
};
