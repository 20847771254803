import { Component, Input } from '@angular/core';

@Component({
  selector: 'pds-module-header',
  templateUrl: './module-header.component.html',
  styleUrls: ['./module-header.component.scss'],
})
export class ModuleHeaderComponent {
  @Input()
  title!: string;

  @Input()
  ctaPath: string | undefined;

  @Input()
  ctaLabel: string | undefined;

  @Input()
  modifier: 'primary' | 'outlined' | undefined;
}
