<div class="pds-multiple-select" *ngIf="source$ | async; let source">
  <div *ngIf="!!value.length" class="pds-multiple-select__tooltip" [class.--disabled]="disabled$ | async">{{ source | selecteds : placeholder }}</div>
  <div role="textbox" [title]="source | selecteds : placeholder" class="pds-multiple-select__input" [class.--disabled]="disabled$ | async" [class.--activated]="active$ | async" (click)="handleOpenEvent()">
    <span class="pds-multiple-select__input__label">
      {{ source | selecteds : placeholder }}
    </span>
    <button type="button" class="pds-multiple-select__input__action">
      <ng-container *ngIf="active$ | async; else arrowDropUpTemplate">
        <span class="material-symbols-outlined">arrow_drop_up</span>
      </ng-container>

      <ng-template #arrowDropUpTemplate>
        <span class="material-symbols-outlined">arrow_drop_down</span>
      </ng-template>
    </button>
  </div>
  <ul class="pds-multiple-select__source" *ngIf="active$ | async">
    <ng-container *ngFor="let item of source$ | async">
      <li role="option" [class.--selected]="item.selected" (click)="handleSelectEvent(item)">{{ item.label }}</li>
    </ng-container>
  </ul>
</div>
