import { Pipe, PipeTransform } from '@angular/core';
import { MultipleItem } from './multiple-select.model';

@Pipe({
  name: 'selecteds',
  standalone: true,
})
export class MultipleSelectPipe implements PipeTransform {
  transform(items: MultipleItem[], placeholder: string, abbreviate = false) {
    const selecteds = items.filter((item) => item.selected);

    if (!selecteds || !selecteds.length) return placeholder;

    if (!abbreviate) selecteds.map((item) => item.label).join(', ');

    return selecteds
      .slice(0, 3)
      .map((item) => item.label)
      .join(', ');
  }
}
