import { ApplicationConfig } from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { KeycloakCredentialsService, keycloakHttpInterceptorProvider, keyclockProviderFactory } from '@peca/keycloak';
import { environment } from '../environments/environment';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

export const appConfig: ApplicationConfig = {
  providers: [
    KeycloakService,
    KeycloakCredentialsService,
    keycloakHttpInterceptorProvider,
    keyclockProviderFactory(environment.keycloak as KeycloakOptions),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
};
