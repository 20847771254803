<div class="pds-form-field">
  <div class="pds-form-field__header">
    <label class="pds-form-field__header__label">{{ label }}</label>
  </div>

  <div class="pds-form-field__input" [class.--has-action]="!!action" [class.--has-icon]="!!icon">
    <ng-container *ngIf="icon">
      <div class="pds-form-field__input__icon" [class.--is-disabled]="model?.disabled">
        <span class="icon">{{ icon }}</span>
      </div>
    </ng-container>

    <ng-content />

    <ng-container *ngIf="action">
      <button type="button" [disabled]="model?.disabled" class="pds-form-field__input__action" (click)="onClickAction()">
        <span class="icon">{{ action }}</span>
      </button>
    </ng-container>
  </div>

  <ng-container *ngIf="errors">
    <div class="pds-form-field__validation">
      {{ errors | formValidation }}
    </div>
  </ng-container>
</div>
