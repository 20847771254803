<ng-container *ngIf="pagination.total > 1">
  <div class="pds-pagination">
    <div class="pds-pagination__page">
      <span>Exibindo</span>
      <select class="pds-pagination__page__input" [disabled]="!!pagination.disabled" [(ngModel)]="sizeControl" (change)="handleSizeValueChanges($event)">
        <ng-container *ngFor="let size of acceptedSizes">
          <option [value]="size">{{ size }}</option>
        </ng-container>
      </select>
      <span>itens por página. Página</span>
      <input type="number" min="1" class="pds-pagination__page__input" [disabled]="!!pagination.disabled" [max]="pagination.total" [(ngModel)]="pageControl" (change)="handlePageValueChanges($event)" />
      <span>de {{ pagination.total }}</span>
    </div>
    <div class="pds-pagination__controls">
      <button class="pds-pagination__controls__control" [disabled]="disabledPreviousPage" (click)="handleFirstPageEventClick()">
        <span class="icon">first_page</span>
      </button>
      <button class="pds-pagination__controls__control" [disabled]="disabledPreviousPage" (click)="handlePreviousPageEventClick()">
        <span class="icon">navigate_before</span>
      </button>
      <button class="pds-pagination__controls__control" [disabled]="disabledNextPage" (click)="handleNextPageEventClick()">
        <span class="icon">navigate_next</span>
      </button>
      <button class="pds-pagination__controls__control" [disabled]="disabledNextPage" (click)="handleLastPageEventClick()">
        <span class="icon">last_page</span>
      </button>
    </div>
  </div>
</ng-container>
