export const menu = {
  enableInsights: false,
  groups: [
    {
      name: 'Cadastros',
      modules: [
        {
          path: 'query',
          label: 'Queries',
          icon: 'convert_to_text',
          roles: [],
        },
      ],
    },
  ],
};
