import { Component } from '@angular/core';

@Component({
  selector: 'pds-panel',
  template: `
    <div class="pds-panel">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent {}
