import { AbstractControl } from '@angular/forms';

export const cep = (control: AbstractControl) => {
  const { value } = control;

  if (!value) return null;

  const cep = Number(value.replace(/\D/g, ''));

  if (!isNaN(cep) && cep > 0) return null;

  return { cep: { message: 'CEP inválido' } };
};
