import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface ViaCEPResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

@Injectable({ providedIn: 'root' })
export class AddressService {
  private readonly resource;

  constructor(private http: HttpClient) {
    this.resource = `https://viacep.com.br`;
  }

  getCEPData(cep: string) {
    return this.http.get<ViaCEPResponse>(`${this.resource}/ws/${cep}/json`);
  }
}
