import { AbstractControl, FormGroup } from '@angular/forms';

function parseHours(control: AbstractControl) {
  return parseInt(control.value.split(':')[0]);
}

function validateRange(start: number, end: number) {
  if (!isNaN(start) && !isNaN(end) && start < end) return true;

  if (isNaN(start) && isNaN(end)) return true;

  return false;
}

export const BusinessHoursValidator = {
  hour: (control: AbstractControl) => {
    const matcher = new RegExp(/^[0|1|2]{1}[0-9]:00$/);
    const { value } = control;
    const hours = parseInt(value.split(':')[0]);

    if (!value) {
      return null;
    }

    if (isNaN(hours) || hours < 0 || hours > 23 || !matcher.test(value)) {
      return { hour: true };
    }

    return null;
  },
  range: (group: FormGroup) => {
    return () => {
      const open = parseHours(group.controls['openHour']);
      const close = parseHours(group.controls['closeHour']);
      const breakStart = parseHours(group.controls['startBreakHour']);
      const breakEnd = parseHours(group.controls['endBreakHour']);

      // no fields filled
      if (isNaN(open) && isNaN(close) && isNaN(breakStart) && isNaN(breakEnd)) {
        return null;
      }

      // opening time gt closing time
      if (!validateRange(open, close)) {
        return { range: true };
      }

      // break start time gt break end time
      if (!validateRange(breakStart, breakEnd)) {
        return { range: true };
      }

      // opening time gt break start time
      if (!isNaN(breakStart) && !validateRange(open, breakStart)) {
        return { range: true };
      }

      // break end time gt closing time
      if (!isNaN(breakEnd) && !validateRange(breakEnd, close)) {
        return { range: true };
      }

      // opening time not defined
      if (isNaN(open) && !isNaN(breakStart)) {
        return { range: true };
      }

      return null;
    };
  },
};
