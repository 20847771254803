import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakCredentialsService } from '../services/keycloak-credentials.service';

export function keycloakGuard(roles: string[], redirectToRootDomain = false) {
  return () => {
    const keycloak = inject(KeycloakCredentialsService);
    const router = inject(Router);

    if (keycloak.credentials === null) {
      const redirectUrl = window.location.pathname + window.location.search;
      router.navigate(['/auth'], { queryParams: { redirectUrl } });
      return false;
    }

    if (roles.length && !roles.some((role) => keycloak.roles?.includes(role))) {
      if (redirectToRootDomain) {
        window.location.href = 'https://peca.ai';
      } else {
        router.navigate(['/']);
      }
      return false;
    }

    return true;
  };
}
