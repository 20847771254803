<div class="spec" *ngIf="specificationInput">
  <mat-form-field>
    <mat-label>Tipo</mat-label>
    <mat-select (selectionChange)="idChanged($event)" *ngIf="!disabled">
      <mat-option *ngFor="let spec of specificationInput.availableSpecifications" [value]="spec.id">{{ spec.description }}</mat-option>
    </mat-select>

    <mat-select *ngIf="disabled" [value]="specificationInput.selectedSpecification.id" disabled>
      <mat-option [value]="specificationInput.selectedSpecification.id">{{
        specificationInput.selectedSpecification.description
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Valores</mat-label>
    <mat-select multiple (selectionChange)="valueChanged($event)" *ngIf="!disabled">
      <mat-option *ngFor="let value of availableValues" [value]="value">{{ value }}</mat-option>
    </mat-select>

    <mat-select multiple (selectionChange)="valueChanged($event)" *ngIf="disabled" [value]="selectedValues">
      <mat-option *ngFor="let value of selectedValues" disabled [value]="value">{{ value }}</mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-icon-button (click)="removeSpec()" *ngIf="!disabled">
    <mat-icon>close</mat-icon>
  </button>
</div>
