import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Provider, inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { KeycloakCredentialsService } from '../services/keycloak-credentials.service';

@Injectable({ providedIn: 'root' })
export class KeycloackInterceptor implements HttpInterceptor {
  private readonly keycloack: KeycloakCredentialsService;
  private readonly router: Router;

  constructor() {
    this.keycloack = inject(KeycloakCredentialsService);
    this.router = inject(Router);
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    const token = this.keycloack.token;
    const authorization = `Bearer ${token}`;

    if (!token) {
      return next.handle(req.clone());
    }

    return next.handle(req.clone({ setHeaders: { Authorization: authorization } })).pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(e: unknown) {
    if (e instanceof HttpErrorResponse && e.status === 401) {
      const redirectUrl = window.location.pathname + window.location.search;
      this.router.navigate(['/auth'], { queryParams: { redirectUrl } });
    }

    return throwError(() => e);
  }
}

export const keycloakHttpInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  multi: true,
  useClass: KeycloackInterceptor,
};
