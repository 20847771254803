import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { QueryResponseDto } from '../../dtos/responses/QueryResponseDto';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { QueryComponent } from '../query/query.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DesignSystemModule } from '@peca/design-system';

@Component({
  standalone: true,
  imports: [RouterModule, CommonModule, QueryComponent, MatCardModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule, DesignSystemModule],
  selector: 'peca-queries',
  templateUrl: './queries.component.html',
  styleUrls: ['./queries.component.scss'],
})
export class QueriesComponent {
  @Input() queries: QueryResponseDto[] = [];
  @Input() loading = true;
  @Output() reload = new EventEmitter<void>();

  public reloadQuery(): void {
    this.reload.emit();
  }
}
