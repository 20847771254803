import { AfterViewInit, Component, ElementRef, forwardRef, HostListener, inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, debounceTime, distinctUntilChanged, fromEvent, map, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'pds-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PDSSelectComponent),
    },
  ],
})
export class PDSSelectComponent implements AfterViewInit, OnDestroy {
  @Input()
  disableSearch: boolean;

  @Input()
  options: any[];

  @Input()
  searchPlaceholder: string;

  @ViewChild('searchInput')
  searchElementRef!: ElementRef;

  options$: BehaviorSubject<any[]>;
  selecteds$: BehaviorSubject<any[]>;
  value: string | number | (string | number)[] | null;
  opened$: BehaviorSubject<boolean>;
  elementRef: ElementRef;
  disabled: boolean;
  destroy$: Subject<void>;
  onChange!: (value: string | number | (string | number)[] | null) => void;
  onTouched!: () => void;

  constructor() {
    this.disableSearch = false;
    this.options = [];
    this.searchPlaceholder = 'Filtro';
    this.options$ = new BehaviorSubject<any[]>([]);
    this.selecteds$ = new BehaviorSubject<any[]>([]);
    this.opened$ = new BehaviorSubject<boolean>(false);
    this.value = null;
    this.elementRef = inject(ElementRef);
    this.disabled = false;
    this.destroy$ = new Subject<void>();
  }

  get searchChanges$() {
    const search = this.searchElementRef.nativeElement;

    return fromEvent<KeyboardEvent>(search, 'keyup').pipe(
      map((event) => (event.target as HTMLFormElement)['value'].trim()),
      distinctUntilChanged(),
      debounceTime(512),
      takeUntil(this.destroy$)
    );
  }

  ngAfterViewInit() {
    if (this.disableSearch) return;
    this.searchChanges$.subscribe(this.onSearchChanges.bind(this));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onSearchChanges(search: string) {
    const options = this.options.filter((option) => option.label.toLowerCase().startsWith(search));
    this.options$.next(options);
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.opened$.next(false);
    }
  }

  writeValue(value: string | number | (string | number)[] | null) {
    this.value = value;
    const options = this.options.map((i) => this.mapSourceToOption(i));
    this.options = options;
    this.options$.next(options);
    this.selecteds$.next(options.filter(({ selected }) => selected));
  }

  registerOnChange(fn: (value: string | number | (string | number)[] | null) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  onTouch() {
    if (this.disabled) return;
    this.opened$.next(!this.opened$.value);
  }

  onSelect(option: any) {
    const options = this.options.map((_option) => ({ ..._option, selected: option.id === _option.id ? !option.selected : _option.selected }));
    const selecteds = options.filter(({ selected }) => selected);
    this.options = options;
    this.options$.next(options);
    this.selecteds$.next(selecteds);
    this.onChange(selecteds.map(({ id }) => id));
    this.searchElementRef.nativeElement.value = '';
  }

  onClickRemove(option: any) {
    if (this.disabled) return;
    const options = this.options$.value.map((_option) => ({ ..._option, selected: option.id === _option.id ? !option.selected : _option.selected }));
    const selecteds = options.filter(({ selected }) => selected);
    this.options = options;
    this.options$.next(options);
    this.selecteds$.next(selecteds);
    this.onChange(selecteds.map(({ id }) => id));
  }

  private mapSourceToOption(source: any) {
    const selected = Array.isArray(this.value) ? this.value.includes(source.id) : this.value === source.id;
    return { label: source.label, id: source.id, selected };
  }
}
