export interface AutocompleteItem {
  id: string | number;
  label: string;
}

export type AutocompleteItemSource = {
  loading?: boolean;
  failure?: boolean;
  data?: AutocompleteItem[];
};
