export const environment = {
  local: false,
  gateway: {
    url: 'https://gw.qa.peca.ai',
  },
  keycloak: {
    config: {
      url: 'https://accounts.qa.peca.ai',
      realm: 'pecaai',
      clientId: 'dialect-admin',
    },
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
    },
  },
};
