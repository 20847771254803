<div class="main-navigation">
  <header class="main-navigation__header">
    <div class="main-navigation__header__col --logotype">
      <button class="main-navigation__trigger" (click)="onClickToogleMenu()">
        <span class="icon">menu</span>
      </button>
      <a routerLink="/" title="Peça Aí - Backoffice">
        <pds-logotype></pds-logotype>
      </a>
    </div>
    <div class="main-navigation__header__col --toolbar"></div>
    <div class="main-navigation__header__col --user-chip">
      <span class="icon">account_circle</span>
      <span>Olá, {{ username }}</span>
    </div>
  </header>
  <div class="main-navigation__content">
    <peca-main-navigation-menu [opened]="opened" [menu]="menu" />

    <main class="main-navigation__content__outlet">
      <router-outlet />
    </main>

    <peca-main-navigation-insights *ngIf="enableInsights" />
  </div>
</div>
