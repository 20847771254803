<div class="pds-modal__wrapper" *ngIf="active">
  <div class="pds-modal" role="dialog" [style.width.px]="width">
    <div class="pds-modal__header">
      <h2 class="pds-modal__header__title">{{ title }}</h2>
      <div class="pds-modal__header__close">
        <button (click)="handleCloseClickEvent()">
          <span class="material-symbols-outlined">close</span>
        </button>
      </div>
    </div>

    <div class="pds-modal__content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
