import { InjectionToken } from '@angular/core';

export interface MainNavigationGroup {
  name: string;
  modules: Array<{
    path: string;
    label: string;
    icon: string;
    roles: string[];
  }>;
}

export interface MainNavigationConfig {
  enableInsights: boolean;
  groups: Array<MainNavigationGroup>;
}

export const MAIN_NAVIGATION_CONFIG = new InjectionToken<MainNavigationConfig>('MAIN_NAVIGATION_CONFIG');
