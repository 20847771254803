import { AbstractControl } from '@angular/forms';

export const length = {
  exactly: (length: number) => {
    return (control: AbstractControl) => {
      const { value } = control;

      if (value.length === length) return null;

      return {
        length: { message: `O campo deve conter exatos ${length} caracteres` },
      };
    };
  },
  max: (length: number) => {
    return (control: AbstractControl) => {
      const { value } = control;

      if (value.length <= length) return null;

      return {
        length: { message: `O campo deve conter até ${length} caracteres` },
      };
    };
  },
};
