import { AbstractControl } from '@angular/forms';

export const email = (control: AbstractControl) => {
  const { value } = control;
  const matcher = new RegExp(
    /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/
  );

  if (!value || matcher.test(value)) return null;

  return { email: { message: 'E-mail inválido' } };
};
