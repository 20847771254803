import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'pds-date-input',
  templateUrl: 'date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
  ],
})
export class DateInputComponent implements ControlValueAccessor {
  private _value = '';

  private onChange!: (value: string) => void;

  private onTouched!: () => void;

  disabled = false;

  placeholder = true;

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  get value(): string {
    return this._value;
  }

  set value(val: string) {
    this._value = val;
  }

  writeValue(value: string): void {
    this._value = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onChangeValue(event: Event) {
    if (!event.target) return;

    const { value } = event.target as HTMLFormElement;

    this.placeholder = new Date(value).toString() === 'Invalid Date';

    this.writeValue(value);
    this.onChange(value);
  }
}
