<ng-container *ngIf="showCombobox">
  <div class="pds-autocomplete__overlay" (click)="handleOverlayClick()"></div>
</ng-container>

<div class="pds-autocomplete" *ngIf="source$ | async; let source">
  <input
    #searchInput
    type="text"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [(ngModel)]="search"
    (focus)="handleSearchFocusEvent()"
    (keydown)="handleSearchKeydownEvent($event)"
  />

  <ng-container *ngIf="showCombobox">
    <div class="pds-autocomplete__combobox" role="combobox">
      <!-- history -->
      <ng-container *ngIf="history$ | async; let history">
        <ng-container *ngIf="history.data?.length">
          <div class="pds-autocomplete__combobox__list">
            <h4 class="pds-autocomplete__combobox__list__title">Histórico</h4>
            <ng-container *ngFor="let item of history.data">
              <li class="pds-autocomplete__combobox__list__item" role="option" data-value="{{ item?.id }}" (click)="selectItem(item)">
                {{ item?.label }}
              </li>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <!-- source -->

      <div class="pds-autocomplete__combobox__list">
        <h4 class="pds-autocomplete__combobox__list__title">Resultados</h4>
        <ng-container *ngIf="source.loading">
          <div class="pds-autocomplete__combobox__list__label">carregando...</div>
        </ng-container>

        <ng-container *ngIf="source.failure">
          <div class="pds-autocomplete__combobox__list__label">Ocorreu um erro ao obter os resultados.</div>
        </ng-container>

        <ng-container *ngIf="source.data && !source.data?.length">
          <div class="pds-autocomplete__combobox__list__label">Nenhum resultado encontrado.</div>
        </ng-container>

        <ng-container *ngIf="!source.failure && !source.loading && !source.data">
          <div class="pds-autocomplete__combobox__list__label">{{ noSearchMessage }}</div>
        </ng-container>

        <ng-container *ngFor="let item of source.data">
          <li class="pds-autocomplete__combobox__list__item" role="option" data-value="{{ item?.id }}" (click)="selectItem(item)">
            {{ item?.label }}
          </li>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
