import { AbstractControl } from '@angular/forms';

export const oneOf = (enumerators: unknown[]) => {
  return (control: AbstractControl) => {
    const { value } = control;

    if (!value || enumerators.includes(value)) return null;

    return {
      oneOf: `O valor "${value}" não corresponde aos valores permitidos [${enumerators.toString()}]`,
    };
  };
};
