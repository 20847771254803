import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DesignSystemModule } from '@peca/design-system';
import { CommonsModule } from '@peca/commons';

@Component({
  standalone: true,
  imports: [RouterModule, DesignSystemModule, CommonsModule],
  selector: 'peca-root',
  template: `
    <router-outlet />
    <peca-toast />
  `,
})
export class AppComponent {}
