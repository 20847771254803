import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { DialectService } from '../../services/dialect.service';
import { QueriesComponent } from '../queries/queries.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { AggregationsService } from '../../services/aggregations.service';
import { SpecificAggregationResponseDto } from '../../dtos/responses/SpecificAggregationResponseDto';
import { SpecificationDto } from '../../dtos/others/SpecificationDto';
import { SpecificationComponent } from '../specification/specification.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { BreadcrumbItem, DesignSystemModule } from '@peca/design-system';
import { SellerService } from '../../services/sellers.service';
import { BehaviorSubject } from 'rxjs';
import { SallesChannelResponse } from '../../dtos/responses/salles-channel.model';

export interface SpecificationInput {
  availableSpecifications: SpecificationDto[];
  selectedSpecification: SpecificationDto;
}

@Component({
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    QueriesComponent,
    MatPaginatorModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatSelectModule,
    SpecificationComponent,
    RouterModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    DesignSystemModule,
    ReactiveFormsModule,
  ],
  providers: [DialectService, AggregationsService, SellerService],
  selector: 'peca-createquery',
  templateUrl: './createquery.component.html',
  styleUrls: ['./createquery.component.scss'],
})
export class CreateQueryComponent implements OnInit {
  breadcrumb: BreadcrumbItem[];
  sellerService: SellerService;
  whiteListControl: FormControl;
  blackListControl: FormControl;
  salesChannels$: BehaviorSubject<SallesChannelResponse[]>;

  constructor(private dialectService: DialectService, private aggregationsService: AggregationsService, private router: Router, private snackBar: MatSnackBar) {
    this.sellerService = inject(SellerService);
    this.breadcrumb = [{ label: 'Queries', path: '/' }];
    this.whiteListControl = new FormControl('');
    this.blackListControl = new FormControl('');
    this.salesChannels$ = new BehaviorSubject<SallesChannelResponse[]>([]);
  }

  ngOnInit(): void {
    this.sellerService.suggest('').subscribe((r) => this.salesChannels$.next(r));

    if (!this.isReadOnly) {
      this.breadcrumb.push({ label: 'Nova Query' });
      return;
    }

    this.dialectService.getQuery(this.queryId ?? '').subscribe((query) => {
      this.breadcrumb.push({ label: query.query });
      this.query = query.query;
      this.selectedProductGroup = query.filter.productGroups[0].id;
      this.productGroups = [
        {
          id: query.filter.productGroups[0].id,
          description: query.filter.productGroups[0].name,
          value: query.filter.productGroups[0].name,
        },
      ];
      this.specifications = query.filter.specifications.map((spec) => ({
        availableSpecifications: [
          {
            id: spec.specificationId,
            description: spec.name,
            values: spec.values,
          },
        ],
        selectedSpecification: {
          id: spec.specificationId,
          description: spec.name,
          values: spec.values,
        },
      }));

      setTimeout(() => {
        this.whiteListControl.setValue(query.saleChannelIdWhiteList || []);
        this.blackListControl.setValue(query.saleChannelIdBlackList || []);
      }, 500);
    });
  }

  get isReadOnly(): boolean {
    return !!this.queryId;
  }

  @Input() public queryId?: string | null;
  public errors: Map<string, string> = new Map<string, string>([['query', '']]);
  public query = '';
  public searchText = '';
  public productGroups: SpecificAggregationResponseDto[] = [];
  public selectedProductGroup?: string;
  public saving = false;
  public loadingSearch = false;
  public loadingSpec = false;

  public specifications: SpecificationInput[] = [];

  changedSelectedSpec(spec: SpecificationInput, selectedSpec: SpecificationDto) {
    spec.selectedSpecification = selectedSpec;
  }

  removeSpec(spec: SpecificationInput) {
    if (this.isReadOnly) return;

    const index = this.specifications.indexOf(spec);
    if (index === -1) return;

    this.specifications.splice(index, 1);
  }

  searchProductGroup(): void {
    if (this.isReadOnly) return;

    if (this.loadingSearch) return;
    if (!this.searchText) return;

    this.loadingSearch = true;

    this.productGroups = [];

    this.aggregationsService.searchProductGroup(this.searchText).subscribe((productGroups) => {
      productGroups.sort((a, b) => a.value.localeCompare(b.value));
      this.productGroups = productGroups;
      if (this.productGroups.length > 0) this.selectedProductGroup = this.productGroups[0].id;

      this.loadingSearch = false;
    });
  }

  addSpec(): void {
    if (this.isReadOnly) return;

    if (this.loadingSpec) return;
    if (!this.selectedProductGroup) return;

    this.loadingSpec = true;

    this.aggregationsService
      .searchSpecifications(
        this.selectedProductGroup,
        this.specifications.map((spec) => spec.selectedSpecification)
      )
      .subscribe((specifications) => {
        this.specifications.push({
          availableSpecifications: specifications,
          selectedSpecification: specifications[0],
        });
        this.loadingSpec = false;
      });
  }

  save() {
    if (this.isReadOnly) return;
    if (this.saving) return;
    if (!this.selectedProductGroup) return;
    if (!this.query) return;

    this.saving = true;
    this.errors.set('query', '');

    this.dialectService
      .saveQuery({
        query: this.query,
        filter: {
          productGroups: [
            {
              id: this.selectedProductGroup,
              name: this.productGroups.find((pg) => pg.id === this.selectedProductGroup)?.value ?? '',
            },
          ],
          specifications: this.specifications.map((spec) => ({
            name: spec.selectedSpecification.description,
            specificationId: spec.selectedSpecification.id,
            values: spec.selectedSpecification.values,
          })),
        },
        saleChannelIdWhiteList: this.whiteListControl.value || [],
        saleChannelIdBlackList: this.blackListControl.value || [],
      })
      .subscribe({
        next: () => {
          this.snackBar.open('Query salva com sucesso', 'Fechar', {
            duration: 3000,
          });
          this.router.navigate(['/']);
        },
        error: (error) => {
          for (const err of error.error.errors) {
            this.errors.set(err.field, err.message);
          }
          this.saving = false;
        },
      });
  }
}
